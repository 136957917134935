
/* MENU 2
--------------------------------------------------------------------------------------------*/

[data-m-type="menu-2"] {

    position: relative;
    background: $ac-menu-2-background;

    @include breakpoint(1060px down){
      #menu-main{
        > li{
          > a{
            margin-right: 16px;
          }
          &:last-child{
            > a {
              margin-right: 0;
            }
          }
        }
      }
    }

    @include breakpoint(medium-portrait down) {
        width: 100%;
        z-index: $ac-menu-2-z-index;
        @if $ac-menu-2-mobile-fixed == true {
            position: fixed;
            top: 0;
            box-shadow: $default-box-shadow;
        } @else {
            position: absolute;
        }
    }

    @if $ac-menu-2-gradient-before == true {
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba($ac-menu-2-gradient-before-color, 0), rgba($ac-menu-2-gradient-before-color, 1));
            z-index: 1;
            @include breakpoint(medium-portrait down) {
                display: none;
            }
        }
    }

    @include breakpoint(medium) {
        @if $ac-menu-2-transparent == true {
            position: absolute;
            background: transparent;
            z-index: $ac-menu-2-z-index;
            width: 100%;
        }
    }
    ul {
        list-style: none;
        margin: 0;
        // display: flex;
    }

    ul.menu.dropdown {
        a {
            margin-right: $default-padding;
        }

        li {
            &:last-child {
                a {
                    margin-right: 0;
                }
            }
        }
    }

    #menu-main{
      > li {
        > ul {
          display: block!important;
          position: absolute;
          opacity: 0!important;
          pointer-events: none!important;
          transition: opacity .4s, transform .4s!important;
          transform: translateX(-.4rem);
        }
        &:hover{
          > ul{
            opacity: 1!important;
            pointer-events: all!important;
            transform: translateX(0);
          }
        }
      }
    }

    .dropdown.menu > li.is-dropdown-submenu-parent {
      > a{
        padding-right: 10px!important;
        margin-right: 17px!important;
        &:after{
          border-color: $brand-white transparent transparent;
          right: 0;
          transition: transform .4s;
          transform: translateY(-50%) translateY(4px);
        }
      }
      &:hover{
        > a:after{
          transform: translateY(-50%) translateY(6px);
        }
      }
    }

    ul.is-dropdown-submenu {
        // display: block;
        padding: .4em 0;
        li.is-submenu-item {
            line-height: 2;
        }
        .current_page_item{
          a{
            color: $brand-primary !important;
          }
        }
        a {
            width: 100%;
            display: inline-block;
            padding: 0 1em;
            color: $default-text-color;
            font-size: 95%;
            text-transform: initial;
            font-weight: normal;

            &:hover {
                color: $brand-primary !important;
            }
        }
            
    }

    a {
        white-space: nowrap;
        user-select: none;
    }

    .search-form {
        padding-right: 10px
    }
}



ul#menu-main {
    display: flex;
}
ul#menu-secondary {
    display: flex;
}
ul#menu-top-cta {
    display: flex;
    font-size: 80%;
    a {
        font-weight: 600;
    }
}

//
//     MENU CTA
//


.ac_menu-2_cta {
    display: flex;
    align-items: center;
}
.ac_menu-2_cta_text {
}
.ac_menu-2_cta_link {
    display: inline-flex;
    align-items: center;
    svg {
        width: 1.6em;
        height: 1.6em;
    }
}
//
//     MENU DIVDER
//

.ac_menu-2_divider {
    position: relative;
    padding: 0 1em;
    height: $ac-menu-2-divider-height;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        height: 100%;
        width: 2px;
        border-right: solid 1px rgba($default-text-color-dark, .1);
    }
}

//
//      STICKY
//

// .ac_menu-2_unsticky_container {
//     width: 100%;
//     height: 2px;
// }
.ac_menu-2_sticky_container {
    margin: 0 auto;
    will-change: contents;
    // transition: height .3s ease;
}
.ac_menu-2_sticky {
    width: 100%;
    z-index:  $ac-menu-2-z-index + 1;
    transition: background-color .3s ease;
    
    &.is-stuck {
        background-color: $ac-menu-2-background;
        z-index:  $ac-menu-2-z-index + 1;
        box-shadow: $default-box-shadow;

        //animate elements on stuck
        .ac_menu-2_logo_img {
            width: $ac-menu-2-logo-width * .5;
            @include breakpoint(medium-portrait down) {
                width: initial;
            }
        }
    }
    @include breakpoint(medium-portrait down) {
        // display: none;
    }
}


//
//     LOGO
//

.ac_menu-2_logo_container {
    padding: $default-padding 0;
    @include breakpoint(medium-portrait down) {
        padding: 0;
    }
    @include breakpoint(medium) {
        flex: 0 0 $ac-menu-2-logo-width;
        margin-right: $default-padding;
    }
}
a.ac_menu-2_logo_link {
    will-change: contents;
    transition: opacity .3s ease;
    margin: 0;
    &:hover {
        opacity: .8;
    }
}
.ac_menu-2_logo_img {
    @include breakpoint(medium-portrait down) {
        height: $ac-menu-2-mobile-height - ( $ac-menu-2-mobile-padding * 2);
        width: initial;
    }
    width: $ac-menu-2-logo-width;
    will-change: contents;
    // transition: width .3s ease;
}



//
//     BAR TOP
//
.ac_menu-2_bar_top {
    @include breakpoint(medium-portrait down) {
        display: none;
    }
    @if $ac-menu-2-bar-top-enabled == false {
        display: none;
    }
}
@if $ac-menu-2-bar-top-enabled == true {
    .ac_menu-2_bar_top {
        width: 100%;
        padding: $default-padding / 2  $default-padding;;
        position: relative;
        z-index: $ac-menu-2-z-index + 2;
        @if $ac-menu-2-transparent == false {
            background: $ac-menu-2-bar-top-background;
        }
    }
    .ac_menu-2_bar_top_container {
        padding: 0 $default-padding;
        max-width: $ac-menu-2-content-width;
        margin: 0 auto;
        display: flex;
        justify-content: $ac-menu-2-bar-top-justify-content;
    }
    .ac_menu-2_bar_top_content {
        line-height: 2;
    }
}


//
//      MAIN
//

.ac_menu-2_main {
    position: relative;
    display: flex;
    z-index: $ac-menu-2-z-index;
    
    @if $ac-menu-2-transparent == false {
        background: $ac-menu-2-main-background;
    }

    @include breakpoint(medium-portrait down) {
        padding: $default-padding / 2;
    }
}
.ac_menu-2_main_container {
    margin: 0 auto;
    max-width: $ac-menu-2-content-width;
    padding: 0 $default-padding;
    @include breakpoint(medium) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: opacity .3s ease, visibility 0s .6s;
    }
}
.ac_menu-2_main_content {
    display: flex;
    justify-content: flex-end;
    align-items: center; //baseline
    @include breakpoint(medium-portrait down) {
        display: none;
    }
}

//
//     BAR BOTTOM
//
.ac_menu-2_bar_bottom {
        @include breakpoint(medium-portrait down) {
        display: none;
    }
    @if $ac-menu-2-bar-bottom-enabled == false {
        display: none;
    }
}
@if $ac-menu-2-bar-bottom-enabled == true {
    .ac_menu-2_bar_bottom {
        width: 100%;
        padding: $default-padding / 2 $default-padding;
        position: relative;
        z-index: $ac-menu-2-z-index + 1;
        @if $ac-menu-2-transparent == false {
            background: $ac-menu-2-bar-bottom-background;
        }
    }
    .ac_menu-2_bar_bottom_container {
        padding: 0 $default-padding;
        max-width: $ac-menu-2-content-width;
        margin: 0 auto;
        display: flex;
        justify-content: $ac-menu-2-bar-bottom-justify-content;
    }
    .ac_menu-2_bar_bottom_content {
        line-height: 2;
    }
}


//
//     BTN SEARCH
//

.ac_menu-2_btn-search {
    display: none;
    display: inline-flex;
    align-items: center;
    svg {
        width: 1.6em;
        height: 1.6em;
    }
}

//
//     MOBILE CONTENT
//

.ac_menu-2_mobile_content {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    @include breakpoint(medium) {
        display: none;
    }
}
.ac_menu-2_mobile_content_divider {
    width: 1px;
    margin: 0 0.7em;
    background: rgba($default-text-color-dark, .1);
}



//
//     MOBILE BUTTONS
//

.ac_menu-2_mobile_btn {
  display: none;
	height: $ac-menu-2-mobile-height - ($ac-menu-2-mobile-padding * 2);
    width: $ac-menu-2-mobile-svg-size + $ac-menu-2-mobile-svg-size;
    padding: (($ac-menu-2-mobile-height - ($ac-menu-2-mobile-padding * 2) - $ac-menu-2-mobile-svg-size) / 2) $ac-menu-2-mobile-svg-size / 2;

    svg {
        width: $ac-menu-2-mobile-svg-size !important;
        height: $ac-menu-2-mobile-svg-size !important;
        fill: $ac-menu-2-mobile-svg-color;
    }

}


//
//     HAMBURGER ICON
//

.ac_menu-2_mobile_btn-hamburger {
    height: $ac-menu-2-mobile-height - ($ac-menu-2-mobile-padding * 2);
    width: $ac-menu-2-mobile-svg-size + $ac-menu-2-mobile-svg-size;
    padding: 12px $ac-menu-2-mobile-padding; // padding is manual

	.burger-icon {
		@include burger(25px, 2px, 4px, $default-icon-color); // width is manual
	}

	@include breakpoint(medium) {
		display: none;
	}
}
body.s_is-open_menu-mobile {
    .burger-icon {
		@include burger-to-cross;
	}
}



//
//     OVERLAY 
//

.ac_menu-2_overlay_container {
    position: fixed;
    z-index: $ac-menu-2-z-index - 1;
    width: 400px;
    height: 100%;
    top: 0;
    right: 0;
    background: $ac-menu-2-overlay-container-background;
    transform: translateX(400px);
    transition: transform .3s ease;
    padding-top: $ac-menu-2-mobile-height;

    @include breakpoint(small only) {
        width: 90vw;
        transform: translateX(100%);
    }
    @include breakpoint(medium) {
		display: none;
	}
    
}
.ac_menu-2_overlay_scroll_container {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
}
.ac_menu-2_overlay_scroll_container_inner {
    min-height: 100vh;
}
.ac_menu-2_overlay_menu_container {
    padding: $default-padding;
    
    ul.menu {
        flex-direction: column;
        @if $ac-menu-2-overlay-menu-primary-border != none {
            border-top: $ac-menu-2-overlay-menu-primary-border;
        }
        padding-bottom: 2em;

        a {
            display: block;
            text-align: $ac-menu-2-overlay-menu-primary-text-align;
            margin-right: 0;
            padding: $default-padding / 2 0;
        }

        > li {
            @if $ac-menu-2-overlay-menu-primary-border != none {
                border-bottom: $ac-menu-2-overlay-menu-primary-border;
            }
            > a {
                padding: $default-padding / 2 0;
            }
        }
        ul.is-accordion-submenu {
            list-style: none;
            margin-left: 1em;
            margin-bottom: 1em;
            li {
                width: 100%;
            }
            a {
                font-size: 90%;
                padding: $default-padding / 4 0;
            }
        }
    }
    .ac_socials {
        display: flex;
        // flex-direction: column;
        align-items: center;
    }
}

body.s_is-open_menu-mobile {
    .ac_menu-2_overlay_container {
        transform: translateX(0);
    }
    overflow: hidden;
}

//
//     OVERLAY BACKDROP
//


.ac_menu-2_overlay_backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $ac-menu-2-overlay-backdrop-background;
    z-index: $ac-menu-2-z-index - 2;
    height: 0;
    opacity: 0;
    transition: opacity .3s ease .3s, height 0s .6s;

    @include breakpoint(medium) {
		display: none;
	}
}
body.s_is-open_menu-mobile {
    .ac_menu-2_overlay_backdrop {
        height: 100%;
        opacity: 1;
        transition: height 0s, opacity .3s ease;
    }
}



//
//     SEARCH
//


.ac_menu-2_search_container {
    display: none;
    position: absolute;
    top: 50%; 
    right: 0;
    width: 50%;
    transform: translateY(-50%) translateX(-2em);
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    @include breakpoint(medium-portrait down) {
        top: 50%;
        right: 1em;
    }
}
body.IE10 {
    .ac_menu-2_search_container {
        display: none;
    }
}

.ac_menu-2_search_input {
    margin: 0;
    font-size: .9rem;
    // border-top: none;
    // border-left: none;
    // border-right: none;
    // box-shadow: none;
    // padding-left: 0 !important;
    
    // &:focus {
    //     border-top: none;
    //     border-left: none;
    //     border-right: none;
    //     box-shadow: none;
    // }
}
.ac_menu-2_search_close {
    position: absolute;
    right: .7em;
    top: 0;
    width: 3em;
    height: 3em;
    padding: .7em;
    svg {
        width: 1.6em;
        height: 1.6em;
        fill: $ac-menu-2-mobile-svg-color;
    }
}

body.s_is-open_menu-search {
    &.IE10 { 
        .ac_menu-2_search_container {
            display: block;
        }
    }
    .ac_menu-2_search_container {
        transform: translateX(0) translateY(-50%);
        opacity: 1;
        pointer-events: auto;
        @include breakpoint(medium-portrait down) {
            transform: translateX(0) translateY(-50%);
        }
    }
}


//
//     SOCIALS
//


.ac_socials_link_icon {
    background: $ac-menu-2-socials-icon-background;
    svg {
        fill:  $ac-menu-2-socials-icon-color;
    }
    &:hover {
        @include breakpoint(large) {
            background: $ac-menu-2-socials-icon-hover-background;
            svg {
                fill: $ac-menu-2-socials-icon-hover-color;
            }
        }
    }
}
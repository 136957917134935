
/* SECTION - MENU 2
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/


$ac-menu-2-transparent: false; 
$ac-menu-2-transparent-on-home: false;
$ac-menu-2-z-index: 2222;
$ac-menu-2-background: $default-background-light;
$ac-menu-2-gradient-before: false;
$ac-menu-2-gradient-before-color: $default-background-dark;
$ac-menu-2-logo-width: 300px; // height for medium up. 
$ac-menu-2-content-width: 1000px;
$ac-menu-2-divider-height: 30px;

$ac-menu-2-bar-top-enabled: true;
$ac-menu-2-bar-top-justify-content: space-between;
$ac-menu-2-bar-top-background: $brand-lightest-gray;

$ac-menu-2-main-background: $default-background-light;
$ac-menu-2-main-content-secondary-menu-color: rgba($default-text-color, .5);

$ac-menu-2-bar-bottom-enabled: true;
$ac-menu-2-bar-bottom-justify-content: space-between;
$ac-menu-2-bar-bottom-background: $brand-primary;

//socials icon
$ac-menu-2-socials-icon-background: $default-icon-color-dark;
$ac-menu-2-socials-icon-hover-background: $brand-secondary;
$ac-menu-2-socials-icon-color: $default-icon-color-light;
$ac-menu-2-socials-icon-hover-color: $brand-primary;

// mobile
$ac-menu-2-mobile-fixed: true;  
$ac-menu-2-mobile-height: 70px;
$ac-menu-2-mobile-padding: 10px;
$ac-menu-2-mobile-svg-size: 25px;
$ac-menu-2-mobile-svg-color: $default-icon-color-dark;

// overlay
$ac-menu-2-overlay-container-background: $brand-white;
$ac-menu-2-overlay-menu-primary-border: dashed 1px rgba($brand-black, .1);
$ac-menu-2-overlay-menu-primary-text-align: center; // left or center
$ac-menu-2-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-2-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-2-overlay-backdrop-background: rgba($default-background-dark, .8);

@import '__menu_2_globals';


/*--------------------------------------------------------------------------------------------*/

body {
    @include breakpoint(medium-portrait down) {
        padding-top: $ac-menu-2-mobile-height;   
    }
}

[data-m-type="menu-2"] {

    // box-shadow: 0 0 10px rgba(black, .2);

    //
    //     SOCIALS
    //
    
    .ac_socials_text {
        display: none;
    }
    .ac_socials_link {
        flex-direction: row-reverse;
    }

    a{
      //centers font vertically
      padding: .2rem 0 .1rem;
    }

}



.ac_menu-2_main_container {
    padding: 0px 10px;
    @include breakpoint(medium-portrait down) {
        display: flex;
        align-items: center;
    }
}


.ac_menu-2_logo_img {
  padding-left: 1.5rem;
  transform: translateY(-5px);
    @include breakpoint(small only) {
        height: auto;
        width: 160px !important;
        max-width: 160px !important;
    }
}

.ac_menu-2_logo_link{
  padding-top: 0;
}

.ac_menu-2_bar_top {
  padding: $default-padding / 4  $default-padding;
    .current-menu-item{
      a{
        color: $brand-primary;
      }
    }
    li {
      a {
        transition: color .4s;
        font-size: 75%;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: inline-block;
        // color: $brand-primary;

        
    }
    &:hover a{
      opacity: 1;
      color: $brand-primary;
  }
    }
    
    &_container{
      padding-left: $default-padding / 2;
      padding-right: $default-padding / 2;
    }
    &_content{
        .ac_menu-2_menu{
            display: flex;
            align-items: center;
        }
    }
}
.ac_menu-2_main_content {
    a {
        text-transform: uppercase;
        font-size: 100%;
        letter-spacing: 1px;
        // color: $brand-gray;
        position: relative;
        &.button{
          padding-top: .4rem!important;
          @include breakpoint(1060px down){
            padding-right: .9rem;
          }
          .svg-container{
            height: 0!important;
            margin-right: .4rem;
          }
        }
        &:hover {
            opacity: .8;
        }
        
        
        svg {
          position: absolute;
          left: 0;
          top: 0;
          fill: $brand-primary;
        }
    }
}
.ac_menu-2_bar_bottom {
  .current-menu-item,
  .current-menu-parent{
    a{
      opacity: 1!important;
    }
  }
    a {
        font-size: 100%;
        font-weight: 500;
        display: inline-block;
        text-transform: uppercase;
        font-weight: 700;    
        transition: opacity .4s, color .4s;    
    }
    #menu-main{
      > li {
        > a {
          opacity: .8;
          color: $brand-white;
        }
        &:hover > a{
          opacity: 1;
        }
      }
    }
  svg {
      fill: rgba($brand-white, .7);
  }
 
}

.ac_menu-2_bar_bottom_container {
    // justify-content: center;
    padding-left: $default-padding / 2;
    padding-right: $default-padding / 2;
}

// .ac_menu-2_sticky {
//     &.is-stuck {
//         .secondary-menu {
//             display: block !important;
//         }
//     }
// }

.ac_menu-2_overlay_menu_primary {
    font-size: 120%; 
    color: $brand-darkest-gray;

}
.ac_menu-2_overlay_menu_secondary {
    color: $brand-darkest-gray;

}

.ac_menu-2_mobile_btn {
    @media screen and (max-width: 370px){
        padding: 12.5px 2px;
        width: 34px;

        &[data-toggle-menu-search] {
            display: none;
        }
    }
}

.ac_menu-2_overlay_container{
  height: calc(100vh - 70px);
  margin-top: 70px;
  padding: 0;
}

.ac_menu-2_overlay_scroll_container{
  overflow-x: hidden;
  &_inner{
    height: 100%;
    min-height: 100%;
    background: $brand-lightest-gray;
  }
  .ac_menu-2_overlay_menu_container{
    padding: 0;
    .menu-mobile-container{
      > ul {
        border: none;
        li{
          border: none;
          a{
            box-shadow: 0 1px 0 rgba($brand-darkest-gray, .05) inset; 
            background: $brand-lightest-gray;
            display: block;
            padding: 1.4rem 0;
            transition: color .4s, background .4s;
            &:after{
              border-color: $brand-darkest-gray transparent transparent;
            }
          }
          > a{
            &:hover{
              color: $brand-white!important;
              &:after{
                border-color: $brand-white transparent transparent;
              }
            }
          }
        }
        > li {
          &:first-child{
            box-shadow: none;
          }
          > a{
            &:after{
              border-width: 4px 4px 4px;
            }
          }
          &.current_page_item{
            > a{
              background: $brand-primary;
              color: $brand-white!important;
            }
          }
          &.current_page_parent{
             > a{
              background: $brand-gray;
             }
          }
          > a{
            &:hover{
              background: $brand-primary;
            }
          }
          &.is-accordion-submenu-parent{
            &:hover{
              > a{
                background: $brand-gray;
                &:hover{
                  background: $brand-primary;
                }
              }
            }
          }
          > ul{
            margin: 0;
            > li {
              a{
                color: $default-text-color!important;
              }
              > a{
                &:hover{
                  background: $brand-primary-darker;
                }
              }
              &.current_page_item{
                
                > a{
                  background: $brand-primary-darker;
                  color: $brand-white!important;
                }
              }
            }
          }
        }
      }
    }
  }
}
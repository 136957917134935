
/* PARTNERS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-partners-items-slider-fouc-height: 100px;
$ac-partners-items-have-padding: true;
$ac-partners-items-have-border-dividers: false;
$ac-partners-items-on-small-two-items-a-row: true; // if true two items per row on small, else one item

// item
$ac-partners-item-margin-bottom: 0; // 1.4em
$ac-partners-item-container-padding: 0;
$ac-partners-item-content-padding: $default-padding; 
$ac-partners-item-content-background: none;
$ac-partners-item-border: $default-border; // defines the appearance, not the display
$ac-partners-item-shadow: false; 

// description
$ac-partners-items-description-enable: false;
$ac-partners-items-description-color: rgba($default-text-color-dark,.6);
$ac-partners-items-description-hover-color: $default-text-color-dark;

// logo
$ac-partners-item-logo-margin-bottom: 0; // 1.4em
$ac-partners-item-logo-height: 100px; 
$ac-partners-item-logo-max-width: 140px;
$ac-partners-item-logo-initial-opacity: 1;
$ac-partners-item-logo-hover-opacity: 1;


@import '__partners_globals';

/*--------------------------------------------------------------------------------------------*/

[data-s-type="partners"] {
    .slick-track{
        display: flex;       
    }
}

[data-s-type="partners"]{
  .ac_heading{
    max-width: 1000px;
    border-bottom: 4px solid $brand-primary;
    &_container{
      padding: 0 10px;
      position: relative;
      z-index: 0;
    }
    &_title{
      margin: 0;
    }
    h2{
      display: inline-block;
      color: #fff;
      padding: .4rem .8rem .2rem;
      margin: 0;
      position: relative;
      &:before{
        position: absolute;
        content: '';
        width: 100%;
        height: calc(100% + 2px);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        left: 0;
        top: 0;
        background: $brand-primary-darker !important;
        z-index: -1;
      }
    }
  }
  // .a_max-width-1000{
  //   max-width: 1085px!important;
  // }
  .ac_partners_container{
    padding: 1rem 0;
    .ac_partners_item{
      padding: 0;
    }
    .ac_partners_item_logo_container{
      position: relative;
      &:before{
        content: '';
        width: 80%;
        height: 120px;
        left: 50%;
        transform: translateX(-50%);
        background: $brand-white;
        top: 0;
        position: absolute;
        border-radius: 5px;
      }
      .ac_partners_item_logo{
        width: 70%;
        height: 120px;
      }
      > * {
        position: relative;
      }
    }
  }
  @include breakpoint(medium down){
    .ac_partners_container{
      .ac_partners_item{
        margin-top: 2rem;
        &:nth-child(1),
        &:nth-child(2){
          margin-top: 0;
        }
      }
    }
  }
  @include breakpoint(1025px down){
    padding-bottom: 0;
    .ac_partners_container{
      background: $brand-lightest-gray;
      padding-bottom: 3rem;
      .ac_partners_item{
        margin-top: 2rem;
        &:nth-child(1),
        &:nth-child(2){
          margin-top: 0;
        }
      }
    }
  }
}
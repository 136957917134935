
/* AC_S_TOPTASKS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-toptasks-items-max-width: $max-content-1200;
$ac-toptasks-items-padding: 0;
$ac-toptasks-items-inner-wrapper-enabled: false;
$ac-toptasks-items-inner-wrapper-padding: $default-padding / 8;
$ac-toptasks-items-inner-wrapper-background-color: $brand-white;
$ac-toptasks-items-have-border-dividers: false;

// item
$ac-toptasks-item-padding: $default-padding / 2;
$ac-toptasks-item-container-padding: $default-padding;
$ac-toptasks-item-has-shadow: false;
$ac-toptasks-item-background-color: $brand-white;
$ac-toptasks-item-background-hover-color: $brand-white;
$ac-toptasks-item-border-radius: 4px; 

// image
$ac-toptasks-item-has-image-container: false; 
$ac-toptasks-item-has-image-container-height: 120px;
$ac-toptasks-item-has-image-container-background-color: none;
$ac-toptasks-item-has-image: false;
$ac-toptasks-item-has-image-initial-fade: .1;
$ac-toptasks-item-has-image-hover-fade: .5;

// icon
$ac-toptasks-item-icon-enbled: true;
$ac-toptasks-item-icon-width: 80px; // px of em
$ac-toptasks-item-icon-padding: 0px; // px or em - is deducted from icon width. Say width is 80px and padding is 15px, the actual icon is 50px;
$ac-toptasks-item-icon-margin-bottom: 0px; 
$ac-toptasks-item-icon-bg: none;
$ac-toptasks-item-icon-hover-bg: inherit; // inherit
$ac-toptasks-item-icon-fill: $brand-primary;
$ac-toptasks-item-icon-hover-fill: $brand-white;

// title
$ac-toptasks-item-title-color: $default-text-color;
$ac-toptasks-item-title-color-hover: rgba($default-text-color, .9);

// description
$ac-toptasks-item-description-enabled: true;
$ac-toptasks-item-description-color: $default-text-color;
$ac-toptasks-item-description-color-hover: rgba($default-text-color, .9);
$ac-toptasks-item-description-margin-bottom: 0;

// btn-container
$ac-toptasks-item-btn-container-enabled: true;
$ac-toptasks-item-btn-container-color: $default-text-color;
$ac-toptasks-item-btn-container-color-hover: rgba($default-text-color, .9);

@import '__toptasks_globals';

/*--------------------------------------------------------------------------------------------*/

.ac_toptasks_item{
  &_container {
    display: block;
    align-items: center;
    padding: 1px;
    position: relative;
    
    &:before{
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      content: '';
      background-image: linear-gradient(0deg, #fff 0%, $brand-primary-darker 25%);
      box-shadow: 0 -2px 5px -1px rgba(0,0,0,.1);
      width: calc(100% + 4px);
      height: 60%;
      position: absolute;
      top: -2px;
      left: -2px;
      z-index: -1;
      transition: height .3s;
    }
    &:after{
      content: '';
      border-radius: 4px;
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
    }
    > * {
      position: relative;
      z-index: 1;
    }
  }
  &:hover{
    .ac_toptasks_item_container{
      transform: translateY(-1rem);
      &:before{
        height: calc(60% + 1rem);
      }
    }
  }
  @include breakpoint(1025px down){
    &:nth-child(3){
      display: none;
    }
  }
}

.ac_toptasks_item_icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg use{
      fill: $brand-primary;
      stroke-width: 0;
    }
}
.ac_toptasks_item_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &_btn-container{
      .button{
        background: none;
        padding: 0;
        color: $brand-secondary;
      }
    }
}

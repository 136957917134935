
/* HERO - 2
--------------------------------------------------------------------------------------------*/

[data-s-type="hero"] {

    // hero
    $hero-height: 55vh; // 100vh, 80vh, 400px, etc
    $hero-min-height: 400px; // optional and handy for smaller sizes, to prevent collapsing of content
    $hero-gradient-before: false;
    $hero-gradient-before-height: 25vh;
    $hero-gradient-before-position: 'top';
    $hero-gradient-color: darken($default-background-dark, 2%);

    // slide
    $hero-slide-max-width: 1000px;
    $hero-slide-content-correction: 0; // -3em // allows for visual correction of outlining content, when using a divider
    $hero-slide-type: 'text'; // text or text-image
    $hero-slide-align: 'left'; // center or left. text-image always implies left aligned text
    $hero-slide-content-text-width: 1*.7; // can be any percentage calculation, eg 1*.8 or 1/4. The image (if applicable) will take the space that is left
    $hero-slide-image-on-small: 'absolute'; // none or absolute; how to show the optional image in a slide on small devices
    $hero-slide-image-on-small-opacity: .4;

    // arrows
    $hero-slide-arrows-enabled: true;
    $hero-slide-arrows-enabled-from: 700px; // 700px
    $hero-slide-arrows-increase-size-from: 1150px;
    $hero-slide-arrows-size-small: 1.5em;
    $hero-slide-arrows-size-large: 2em;
    $hero-slide-arrows-position: 'bottom-centered'; // left-and-right-centered, bottom-centered
    $hero-slide-arrows-bottom-position: 10em; // if bottom-centered
    $hero-slide-arrows-color:  $default-icon-color-dark;
    $hero-slide-arrows-hover-color: rgba( $default-icon-color-dark, .5);

    // dots
    $hero-slide-dots-border: solid 2px rgba($brand-white, .2); 
    $hero-slide-dots-border-active: solid 2px rgba($brand-white, .2); 
    $hero-slide-dots-background: transparent; 
    $hero-slide-dots-background-active: $brand-white; 

    // link to content
    $hero-link-to-content-enabled: true;
    $hero-link-to-content-bottom: 3em;
    $hero-link-to-content-enable-for-medium-portrait-down: false;
    $hero-link-to-content-color: rgba($brand-primary, 1);
    $hero-link-to-content-hover-color: rgba($brand-primary, .8);


    @import '_hero_globals';


    /*--------------------------------------------------------------------------------------------*/
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      height: 1rem;
      transform: translateY(100%);
      background: $brand-primary;
      width: 100%;
    }

    h1, h2, h3 {
        line-height: 1;
        // color: $brand-white;
        font-weight: 600;
    }
    p {
        line-height: 1.5;
        // color: $brand-white;
    }
    
    * {
        &:focus {
            outline: none;
        }
    }

    .ac_hero_container {
        @include breakpoint(medium-portrait down){
            min-height: 460px;
            .ac_hero_slide_content_container{
              transform: translateY(-10px);
            }
        }
    }
    
    .ac_hero_slide_content_text {
        // background: $brand-white;
        padding: $default-padding;
        flex: 0 1 71%;
        max-width: 71%;
        @include breakpoint(large) {
            padding: 2rem;
        }
        @include breakpoint(800px down) {
          flex: 0 1 100%;
          max-width: 100%;
        }

        P {
            color: $brand-white;
            text-shadow: 2px 2px 5px #000000;
        }

        .ac_cta_column{
          a{
            padding: .2rem 0 .1rem .8rem;
            background: $brand-primary;
            color: rgba($brand-white, .8);
            transition: color .4s;
            position: relative;
            border-radius: 2px;
            overflow: hidden;
            .svg-container{
              margin-left: .6rem;
              svg{
                position: absolute;
                top: 0;
                right: -.1rem;
                width: 2.24em;
                height: 100%;
                background: $brand-primary-darker;
                use{
                  transition: transform .4s;
                }
              }
            }
            &:hover{
              color: rgba($brand-white, 1);
              svg{
                use{
                  transform: translateX(.1rem);
                }
                
              }
            }
          }
        }
    }

    .ac_hero_slide_content_text_title {
        * {
            font-size: 140%;
            color: $brand-white;
            text-shadow: 2px 2px 5px #000000;
            // color: $default-text-color-light;
            @include breakpoint(medium) {
                font-size: 180%;
            }
        }
    }
    

    .slick-dots {
        bottom: inherit;
        top: 2rem;
    }

    

} // end of scope
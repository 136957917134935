/* SECTION CONTROL
--------------------------------------------------------------------------------------------*/
[data-s-id="home-toptasks"] {
    position: relative;
    z-index: 3;
    padding-top: 0 !important;
    margin-top: -6rem;
}

[data-s-id="home-services"] {
    .ac_item_content {
        background: $brand-white;
    }
}

[data-s-type="schoots-packages"]{
  .ac_content_1-3{
    margin: 0 2rem;
    border-radius: 4px;
    padding: 0 0 1rem 0;
    border: 3px solid $brand-light-gray;
    background: $brand-white;
    overflow: hidden;
    p{
      padding: 0 2rem;
    }
    h3{
      padding: 1rem 2rem;
      margin-bottom: 1.4rem;
      background: $brand-light-gray;
    }
    &:nth-child(2){
      border: 3px solid $brand-darkest-gray;
      h3{
        background: $brand-darkest-gray;
        color: $brand-lightest-gray;
      }
    }
    &:nth-child(3){
      border: 3px solid $brand-primary-darker;
      h3{
        background: $brand-primary-darker;
        color: $brand-white;
      }
    }
    @include breakpoint(medium down){
      width: calc(100% - 4rem);
      margin-top: 20px;
    }
  }
}
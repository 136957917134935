
/* FOOTER 1 - 1
--------------------------------------------------------------------------------------------*/

[data-f-type="footer-1"] {


    // footer
    $footer-background: $brand-darkest-gray;
    $footer-content-width: $max-content-1000;
    
    // primary container
    $footer-primary-enabled: true;
    $footer-primary-background: $brand-darkest-gray;
    $footer-primary-padding: $default-padding * 2 $default-padding / 2;
    $footer-primary-color: $default-text-color-light;
    $footer-primary-link-color: rgba($brand-primary, 1);
    $footer-primary-link-hover-color: rgba($brand-primary, .7);
    $footer-primary-column-1-enabled: true;
    $footer-primary-column-2-enabled: true;
    $footer-primary-column-3-enabled: true;
    $footer-primary-column-4-enabled: true;
    // width of columns above medium. Any combination is possible, as long as it adds up to 100
    $footer-primary-column-width-1: 1*.20;
    $footer-primary-column-width-2: 1*.16;
    $footer-primary-column-width-3: 1*.26;
    $footer-primary-column-width-4: 1*.30;
    $footer-primary-collapsed-hide-column-1: false;
    $footer-primary-collapsed-hide-column-2: false;
    $footer-primary-collapsed-hide-column-3: false;
    $footer-primary-collapsed-hide-column-4: false;
    $footer-primary-collapsed-align-center: true;
    
    // secondary container
    $footer-secondary-enabled: false;
    $footer-secondary-background: rgba($brand-white, .1);
    $footer-secondary-padding: $default-padding / 2 $default-padding / 2;
    $footer-secondary-color: rgba($brand-white, .5);
    $footer-secondary-link-color: rgba($brand-white, .5);
    $footer-secondary-link-hover-color: rgba($brand-white, .9);
    $footer-secondary-column-1-enabled: true;
    $footer-secondary-column-2-enabled: true;
    $footer-secondary-column-width-1: 1*.5;
    $footer-secondary-column-width-2: 1*.5;
    $footer-secondary-column-1-justify: flex-start;
    $footer-secondary-column-2-justify: flex-end;
    $footer-secondary-collapsed-align-center: true;
    
    // socials
    $footer-socials-icon-background: $brand-tertiary-darker;
    $footer-socials-icon-hover-background: $brand-white;
    $footer-socials-icon-color: $brand-white;
    $footer-socials-icon-hover-color: $brand-tertiary-darker;
    

    @import '../ac_f__footer-globals/__footer_globals';


    /*--------------------------------------------------------------------------------------------*/
    
    // globals
    @if $footer-background != transparent or $footer-background != none {
        background: $footer-background;
    }
    font-size: 90%;

    @include breakpoint(medium only) {
        //padding-bottom: 80px;
    }
    
    h2 {
        font-size: 120%;
        margin-bottom: $default-padding / 2;
        // opacity: .5;
    }

    ul {
        list-style: none;
        margin: 0;
    }


    //
    //      COLUMN
    //

    // margin fix for news items, if in second column
    .ac_footer_column {
        &:nth-child(2) {
            .ac_footer_link {
                line-height: 1.3;
                margin-bottom: $default-padding;
            }
        }
    }

    .ac_footer-1_editor{
      margin-bottom: 0;
    }

    .ac_footer_primary_column{
      a {
        color: $brand-lightest-gray!important;
        pointer-events: all;
        display: inline-block;
        &:hover{
          color: $brand-primary !important;
        }
      }
      &:nth-child(3){
        .ac_footer-1_editor{
          pointer-events: none;
          &:hover{
            a{
              color: $brand-primary !important;
            }
          }
          
        }
      }
      @include breakpoint(medium up){
        &:nth-child(2),
        &:nth-child(3){
          margin-right: 4%;
          box-shadow: -2px 0 0 rgba($brand-white, .6) inset;
        }
        &:nth-child(2){
          padding-top: 24px;
        }
      }
      @include breakpoint(medium-portrait down){
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        &:nth-child(1){
          margin-bottom: 0px;
        }
        &:nth-child(2),
        &:nth-child(3){
          padding-bottom: 4%;
          margin-bottom: 4%;
          box-shadow: 0 -2px 0 rgba($brand-white, .6) inset;
        }
      }
    }


    //
    //      PRIMARY
    //

    .ac_footer_primary_link {
        display: block;
    }
    .ac_footer_primary_link_copy_above {
        text-transform: uppercase;
        display: block;
        opacity: .5;
        font-size: 80%;
    }


    //
    //      SECONDARY
    //
    .ac_footer_secondary {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 90%;
    }
    .ac_footer_secondary_link {
        display: block;
    }


} // end of scope


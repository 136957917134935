/* PAGE CONTROL
--------------------------------------------------------------------------------------------*/

//stretch pages so footer is always at bottom
//@include breakpoint(medium up){
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .main-container{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      section{
        width: 100%;
        &:nth-last-of-type(2){
         flex-grow: 1;
        }
      }
    }
  }
//}

.page-id-181{
  #contact-sectie-titel-5-5{
    .ac_content_container{
      background: $brand-lightest-gray;
      color: $brand-white;
    }
  }
}

.a_bg_gradient_brand_primary{
  background: none!important;
}

.page-id-223,
.page-id-221{
  .a_bg_gradient_brand_primary{
    background: none!important;
  }
}

.page-id-221{
  [data-s-id="privacy-sectie-titel-3"]{
    padding-top: 0!important;
  }
}

.page-id-223{
  [data-s-id="algemene-voorwaarden-hero-alt"]{
    padding-bottom: 0!important;
  }
  [data-s-id="algemene-voorwaarden-sectie-titel-1"]{
    margin-bottom: 60px!important;
  }
}
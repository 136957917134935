/* STUCTURE
--------------------------------------------------------------------------------------------*/
body {
  margin: 0 auto;
}

figure {
    margin: 0;
}

section[data-s-type]:not([data-s-type="hero"]){
  margin: 0 auto;
  max-width: 1200px;
}

a {
  // transition: all $default-transition-speed;
}



// fix for non animatables
[data-anime-type="none"] [data-anime-elem]{ 
    opacity: 1; 
}

h2{
  font-weight: 600!important;
}

// .svg_line {
//     svg {
//         fill: none !important;
//         stroke: green;
//         stroke-width: 5px;
//         stroke-linecap: round;
//         stroke-miterlimit: 100;
//         stroke-dasharray: 1000;
//         stroke-dashoffset: 1000;
//         animation: dash 3s ease-out forwards;
//         animation-delay: 1s;
//     }
// }

// @keyframes dash {
//   to {
//     stroke-dashoffset: 0;
//   }
// }


/* BRANDS - 1
--------------------------------------------------------------------------------------------*/

$ac-brands-items-slider-fouc-height: 100px;
$ac-brands-items-have-padding: true;
$ac-brands-items-have-border-dividers: false;
$ac-brands-items-on-small-two-items-a-row: true; // if true two items per row on small, else one item

// item
$ac-brands-item-margin-bottom: 0; // 1.4em
$ac-brands-item-container-padding: $default-padding 0;
$ac-brands-item-content-padding: $default-padding; 
$ac-brands-item-content-background: none;
$ac-brands-item-border: $default-border; // defines the appearance, not the display
$ac-brands-item-shadow: false; 

// description
$ac-brands-items-description-enable: false;
$ac-brands-items-description-color: rgba($default-text-color-dark,.6);
$ac-brands-items-description-hover-color: $default-text-color-dark;

// logo
$ac-brands-item-logo-margin-bottom: .6em; // 1.4em
$ac-brands-item-logo-height: 100px; 
$ac-brands-item-logo-max-width: 200px;
$ac-brands-item-logo-initial-opacity: 1;
$ac-brands-item-logo-hover-opacity: 1;


@import '__brands_globals';

/*--------------------------------------------------------------------------------------------*/

[data-s-type="brands"]{
  .ac_heading{
    max-width: 1000px;
    &_container{
      padding: 0 10px;
    }
    &_title{
      margin: 0;
    }
    h2{
      display: inline-block;
      background: $brand-lightest-gray;
      padding: .4rem .8rem .2rem;
      margin: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
  // .a_max-width-1000{
  //   max-width: 1085px!important;
  // }
  .ac_brands_container{
    padding: 0;
  }
  .slick-list{
    padding: 3rem 1rem;
    background: $brand-lightest-gray;
    .ac_brands_item_logo_container{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: -5px;
        z-index: 0;
        width: 70%;
        background-color: $brand-white;
        left: 50%;
        height: 110px;
        border-radius: 5px;
        transform: translateX(-50%);
      }
      > * {
        position: relative;
      }
    }
  }
  .slick-arrow{
    transition: transform .4s;
    &.slick-prev:hover{
      transform: translateX(-.1rem) translateY(-50%);
    }
    &.slick-next:hover{
      transform: translateX(.1rem) translateY(-50%);
    }
    svg{
      fill: $default-text-color;
    }
  }
}

.ac_brands_item {
    display: block !important;
    max-width: 100%;
    transition: opacity .3s ease;
    padding: 0 .4rem;
    &:hover {
        opacity: 1 !important;
    }
}

button.slick-arrow{
    svg{
        fill: $brand-primary;
    }
}